@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

/*
font-family: 'Libre Baskerville', serif;
font-family: 'Merriweather', serif;
font-family: 'Source Sans Pro', sans-serif;
*/
$color-primary: #E04038; // #E04038 #FFCC00
$color-primary-hover: #ff584f;
$color-background: #141515;
$font-primary: "Libre Baskerville", Helvetica, Arial, sans-serif;
$font-header: "Source Sans Pro", serif;
$font-size: 16px;
$transition-time: 0.4s;
$wide-break: 1600px;



@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Regular.woff');
	src: url('./fonts/macklin/Macklin Display Regular.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Regular.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Regular.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Regular.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Regular Italic.woff');
	src: url('./fonts/macklin/Macklin Display Regular Italic.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Regular Italic.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Regular Italic.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Regular Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Light.woff');
	src: url('./fonts/macklin/Macklin Display Light.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Light.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Light.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Light.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Light It.woff');
	src: url('./fonts/macklin/Macklin Display Light It.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Light It.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Light It.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Light It.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Medium.woff');
	src: url('./fonts/macklin/Macklin Display Medium.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Medium.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Medium.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Medium It.woff');
	src: url('./fonts/macklin/Macklin Display Medium It.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Medium It.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Medium It.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Medium It.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Bold.woff');
	src: url('./fonts/macklin/Macklin Display Bold.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Bold.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Bold.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Macklin";
	src: url('./fonts/macklin/Macklin Display Bold Italic.woff');
	src: url('./fonts/macklin/Macklin Display Bold Italic.woff') format('woff'),
		//url('./fonts/macklin/Macklin Display Bold Italic.woff2') format('woff2'),
		url('./fonts/macklin/Macklin Display Bold Italic.eot') format('embedded-opentype'),
		url('./fonts/macklin/Macklin Display Bold Italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}


body,
html {
	height: 100%;
}

body {
	font-family: $font-primary;
	color: #fff;
	background-color: $color-background;
	font-size: $font-size;
	font-weight: 400;
	line-height: 1.4em;
	margin: 0;
	overflow-x: hidden;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	@media (max-width: 800px) {
		font-size: 15px;
	}
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

p {
	text-align: left;
	margin-top: 0;
	margin-bottom: 1.55em;
}

a {
	color: $color-primary;
	text-decoration: underline;
}

a:focus,
a:hover {
	color: $color-primary-hover;
	text-decoration: underline;
}

a,
p,
ul>li,
ul>li a {
	white-space: normal;
	word-wrap: break-word;
}

img {
	max-width: 100%;
	margin: auto;
	display: block;
	text-align: center;
}

ul {
	margin-top: 0;
	margin-bottom: 1.55em;
	padding-left: 20px;
}

h1 b,
.highlight {
	font-weight: inherit;
	color: $color-primary;
}

h1 {
	font-family: $font-header;
	display: block;
	color: $color-background;
	letter-spacing: -0.15rem;
	font-size: 7.5rem;
	line-height: 1;
	margin-top: 0em;
	margin-bottom: 0.3rem;
	font-weight: 900;

	@media (max-width: 1200px) {
		font-size: 5.5rem;
	}

	@media (max-width: 800px) {
		font-size: 4.5rem;
	}
	@media (max-width: 580px) {
		font-size: 4rem;
	}
	@media (max-width: 456px) {
		font-size: 3rem;
	}

}

h2 {
	display: block;
	color: #fff;
	font-weight: 100;
	font-size: 2.5rem;
	line-height: 1;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;

	@media (max-width: 1200px) {
		font-size: 2.25rem;
	}

	@media (max-width: 800px) {
		font-size: 1.75rem;
	}
	@media (max-width: 580px) {
		font-size: 1.5rem;
	}
	@media (max-width: 456px) {
		font-size: 1.2rem;
	}
}

h3 {
	font-family: $font-header;
	color: #fff;
	font-size: 4rem;
	line-height: 1;
	margin-top: 0em;
	margin-bottom: 2rem;
	font-weight: 700;

	@media (max-width: 1200px) {
		font-size: 3rem;
	}

	@media (max-width: 800px) {
		font-size: 2.8rem;
	}
	@media (max-width: 800px) {
		font-size: 2.5rem;
	}
	@media (max-width: 800px) {
		font-size: 2.6rem;
	}
}

h4 {
	font-family: $font-header;
	color: #fff;
	font-size: 2.2rem;
	line-height: 1;
	margin-top: 0.75rem;
	margin-bottom: 2rem;
	font-weight: 700;

	@media (max-width: 1200px) {
		font-size: 2rem;
	}
}


.container {
	width: 100%;
	max-width: 1000px;
	display: block;
	padding: 0px 20px;
	margin-right: auto;
	margin-left: auto;
	@media (max-width: 1100px) {
		max-width: 100%;
	}
}

.container-lg {

	max-width: $wide-break;
	padding: 0px 20px;
	@media (max-width: 1100px) {
		max-width: 100%;
	}
}

.row {
	display: table;
	width: 100%;
	position: relative;
}

.col {
	display: table-cell;
	vertical-align: top;
}

.wrapper {
	display: flex;

}

section {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;

	>div {
		width: 100%;
	}

	&:not(#contact) {
		min-height: 100vh;



	}
}

.modal {
	z-index: 9999;
	display: flex;
	visibility: hidden;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	-webkit-transition: all $transition-time*0.75 ease-in-out;
	-moz-transition: all $transition-time*0.75 ease-in-out;
	-o-transition: all $transition-time*0.75 ease-in-out;
	transition: all $transition-time*0.75 ease-in-out;
	.container {
		-webkit-transform: translateX(50%);
		transform: translateX(50%);
		-webkit-transition: all $transition-time*0.5 ease-in-out;
	-moz-transition: all $transition-time*0.5 ease-in-out;
	-o-transition: all $transition-time*0.5 ease-in-out;
	transition: all $transition-time*0.5 ease-in-out;
	//-webkit-transition-delay: 0.05s;
	//	transition-delay: 0.05s;
	}
	.modal-overlay {
		background: rgba(0, 0, 0, 0.8);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: 9998;
		cursor: pointer;
	}

	&.shown {
		position: fixed;
		visibility: visible;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		pointer-events: all;
		width: auto;
		height: auto;
		overflow: auto;
		opacity: 1;
		.container {
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
		

	}

	img {
		max-height: 90vh;
	}

	.container {
		position: relative;
		z-index: 9999;
	}
}

#contact {
	min-height: 20vh;
}

@media (max-width: 1200px) {
	section:not(#home):not(#contact) {
		min-height: 50vh;
	}
}

.background-video {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	//min-height: calc(100vh - 59px);

	div>div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

	}

	video {
		object-fit: cover;
		pointer-events: none;
		min-width: 100%;
		min-height: 100%;
		display: inline-block;
		vertical-align: baseline;
	}
}

$dot-size: 8px;

.list {
	padding-left: 0px;
	margin-left: 0px;
	list-style: none;
	margin-left: 120px;
	@media (max-width: 1200px) {
		margin-left: 0px;
	}
	

	> li {
		position: relative;
		padding-left: 20px;
		margin-left: 0px;
		padding-bottom: 10px;
		margin: 0;
		cursor: pointer;

		@media (max-width: 1200px) {
			padding-left: 110px;
		}
		@media (max-width: 456px) {
			padding-left: 0px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			border-left: 1px solid #fff;

			@media (max-width: 1200px) {
				left: 90px;
			}
			@media (max-width: 456px) {
				display: none;
				visibility: hidden;
				width: 0;
				height: 0;
			}
		}
		@media (max-width: 580px) {
			$dot-size: 6px;
		}

		&::after {
			content: '';
			position: absolute;
			width: $dot-size;
			height: $dot-size;
			margin-left: -($dot-size/2);
			border-radius: $dot-size;
			background-color: $color-background;
			left: 0;
			top: $dot-size/2;
			border: 1px solid #fff;
			-webkit-transition: all $transition-time/2 ease-in-out;
			-moz-transition: all $transition-time/2 ease-in-out;
			-o-transition: all $transition-time/2 ease-in-out;
			transition: all $transition-time/2 ease-in-out;

			

			@media (max-width: 1200px) {
				left: 90px;
			}
			@media (max-width: 456px) {
				display: none;
				visibility: hidden;
				width: 0;
				height: 0;
			}
		}

		&:first-child {
			&::before {
				top: $dot-size;
			}
		}

		&:last-child {
			&::before {
				height: $dot-size;
			}
		}

		.title {
			font-family: $font-header;
			font-weight: 700;
			margin-bottom: 3px;
			font-size: 1.1rem;

			@media (max-width: 1200px) {
				font-size: 1.15rem;
			}

			

			>div {
				display: inline;
				font-weight: 400;
				font-style: italic;
				font-size: 0.7rem;

				font-family: $font-primary;
				padding-left: 2px;

				@media (max-width: 1200px) {
					font-size: 1rem;
				}
				@media (max-width: 456px) {
					display:block;
					margin-top: 4px;
					font-size: 1rem;
				}
			}

			span {
				padding-left: 3px;
				font-size: 0.95rem;
				color: $color-primary;
				font-weight: 900;

				@media (max-width: 1200px) {
					font-size: 1rem;
				}
			}

			i {
				vertical-align: middle;
				color: $color-primary;
				font-size: 0.95rem;
				margin-right: 4px;
				margin-top: -3px;
				display: inline-block;
			}
		}

		.subtitle {
			position: absolute;
			text-align: right;
			right: 100%;
			left: -120px;
			top: 0;
			font-size: 0.8rem;
			padding-right: 20px;

			@media (max-width: 1200px) {
				left: 0;
				width: 95px;
				font-size: 0.75rem;
			}
			@media (max-width: 580px) {
				width: 100px;
				font-size: 0.68rem;
			}
			@media (max-width: 456px) {
				position: relative;
				right: 0;
				left: 0;
				font-size: 1rem;
				padding: 0;
				width: 100%;
				text-align: left;
			}
		}

		.description {
			font-size: 0.8rem;
			line-height: 1.5;
			color: rgba(197, 197, 197, 0.75);
			position: relative;

			-webkit-transition: all $transition-time ease-in-out;
			-moz-transition: all $transition-time ease-in-out;
			-o-transition: all $transition-time ease-in-out;
			transition: all $transition-time ease-in-out;

			span {
				width: 100%;
				display: block;
			}

			@media (max-width: 1200px) {
				font-size: 0.9rem;
			}



		}

		&:hover {
			.description {
				color: rgba(197, 197, 197, 1);

			}

			&::after {
				$dot-lg: $dot-size*1.55;
				width: $dot-lg;
				height: $dot-lg;
				margin-left: -($dot-lg/2);
				border-radius: $dot-lg;
				top: $dot-lg*0.25;
			}

		}
	}
}

#background {
	background-color: $color-primary;
	background: url("https://hennessy.tv/assets/images/background.png") center center;
	background-size: cover;
	position: relative;
	background-attachment: fixed;
	height: 100vh;
	width: 100%;
}

.arrow {
	text-align: center;
	margin-top: 2rem;
	-webkit-transition: all $transition-time*0.75 ease-in-out;
	-moz-transition: all $transition-time*0.75 ease-in-out;
	-o-transition: all $transition-time*0.75 ease-in-out;
	transition: all $transition-time*0.75 ease-in-out;

	

	a {
		color: #fff;
		cursor: pointer;
		font-size: 30px;
		width: 45px;
		height: 45px;
		padding-top: 6px;
		border: 3px solid #fff;
		display: inline-block;
		border-radius: 50px;
		text-decoration: none;

	}

	&:hover {
		transform: scale(1.12);
	}
}

.masonry {
	>div {
		>div {
			cursor: pointer;
			-webkit-transition: all $transition-time ease-in-out;
			-moz-transition: all $transition-time ease-in-out;
			-o-transition: all $transition-time ease-in-out;
			transition: all $transition-time ease-in-out;

			//&:hover {
				//position: relative;
				//z-index: 1;
				//transform: scale(1.05);
			//}
		}
	}
}

.icon-list {
	display: flex;
	justify-content: center;

	a {
		display: block;
		position: relative;
		font-size: 2.5rem;
		margin-right: 20px;
	}
}



#home {
	//
	.container {
		position: relative;
		z-index: 1;
	}
	&.mobile {
		padding-top: 0px;
		padding-bottom: 0px;
		height: auto;
		min-height: 95vh;
		background-color: $color-primary;
		.background-video, #background {
			display: none;
			visibility: hidden;
			width: 0;
			height: 0;
		
		}
		.container {
			position: inherit;
			> div {
				will-change: unset !important;
    			transform: translateY(0) !important;
			}
		}
		.arrow {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			bottom: 35px;
		}
		h2 {
			margin-top: 0.25rem;
		}
		#background {
			background: $color-primary;
		}
	}
	


}

#about {
	.wrapper {
		@media (max-width: 1200px) {
			display: block
		}

		>div {
			flex: 58%;

			@media (min-width: 1201px) {
				&:last-child {
					flex: 42%;
					padding-left: 125px;
					flex-grow: 0;
					flex-shrink: 1;
				}
			}
		}
	}

	.skills {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		font-family: $font-header;
		font-weight: 900;
		text-transform: uppercase;
		> div {
			background: $color-primary;
			//background: saturate(lighten($color-primary, 12%), 100%);
			color: $color-background;
			padding: 5px 10px;
			margin: 0 3px 3px 0;
			$darker1: darken($color-primary, 5%);
			/*&:nth-child(n+5) {
				background: desaturate($darker1,15%);
				//background: darken($color-primary, 0%);
			}

			$darker2: darken($color-primary, 10%);
			&:nth-child(n+9) {
				background: desaturate($darker2, 20%);
				//background:  desaturate(darken($color-primary, 5%), 5%);
			}*/
		}
	}

	p {
		text-align: justify;
	}
}

.gallery-item {
	position: relative;
	overflow: hidden;
	//-webkit-transform: translateZ(0);
	//transform: translateZ(0);


	img {
		webkit-filter: blur(0px);
		filter: blur(0px);
		width: 100%;
		-webkit-transition: all $transition-time ease-in-out;
		-moz-transition: all $transition-time ease-in-out;
		-o-transition: all $transition-time ease-in-out;
		transition: all $transition-time ease-in-out;
		border: 2px solid transparent;
	}

	&:hover img {
		webkit-filter: blur(2px);
		filter: blur(2px);
		border: 2px solid $color-background;
		//opacity: 0.7;

	}

	&:before,
	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: auto;
		width: 120%;
		right: 0;
		content: '';
		background-color: $color-background;
		opacity: 0;
		-webkit-transition: all $transition-time ease;
		transition: all $transition-time ease;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-transition: all $transition-time ease;
		transition: all $transition-time ease;
		z-index: 1;
	}

	&:before {
		-webkit-transform: skew(0deg) translateX(-80%);
		transform: skew(0deg) translateX(-80%);
	}

	&:after {
		-webkit-transform: skew(-0deg) translateX(-70%);
		transform: skew(-0deg) translateX(-70%);
	}

	.gallery-overlay {
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		z-index: 1;
		bottom: 0;
		padding: 25px 40% 25px 20px;
		width: 90%;
	}

	&:hover:before {
		-webkit-transform: skew(15deg) translateX(-15%);
		transform: skew(15deg) translateX(-15%);
		-webkit-transition-delay: 0.05s;
		transition-delay: 0.15s;
		opacity: 0.75;
	}

	&:hover:after {
		-webkit-transform: skew(-15deg) translateX(-10%);
		transform: skew(-15deg) translateX(-10%);
		opacity: 0.75;
	}

	&:hover:before,
	&:hover:after {
		opacity: 0.65;
	}



	/*&:before {
		display: block;
		content: "";
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 75%;
		z-index: 1;
		-webkit-transition: all $transition-time ease-in-out;
		-moz-transition: all $transition-time ease-in-out;
		-o-transition: all $transition-time ease-in-out;
		transition: all $transition-time ease-in-out;
		opacity: 0;
	}*/
	.gallery-overlay {

		display: block;
		opacity: 0;
		position: absolute;
		//background: rgba(255,255,255, 0.85);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		text-align: left;
		padding: 15px;
		color: #fff;


		text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
		//text-shadow: 0px 0px 5px #fff;
		font-weight: 900;
		/*$text-stroke: #fff;
		text-shadow: -1px -1px 0 $text-stroke, 1px -1px 0 $text-stroke, 
		-1px 1px 0 $text-stroke, 1px 1px 0 $text-stroke;*/
		-webkit-transition: all $transition-time ease-in-out;
		-moz-transition: all $transition-time ease-in-out;
		-o-transition: all $transition-time ease-in-out;
		transition: all $transition-time ease-in-out;
		z-index: 2;
	}

	.title {
		font-family: $font-header;
		font-weight: 900;
		font-size: 1.25rem;
		//color: $color-primary;
	}

	.description {
		font-size: 0.8rem;
		line-height: 1.3;
		margin-top: 3px;
		font-weight: 300;
		font-style: italic;
	}

	&:hover {
		.gallery-overlay {
			opacity: 1;
		}


	}
}

#portfolio {
	.figure {
		position: relative;
		display: flex;
	}

	.figure-thumb {
		width: 100%;
		max-width: 600px;
	}

	.figure-inner {
		padding-left: 30px;
	}

	.wrapper {
		//$grid-gap: 2px;
		flex-wrap: wrap;		

		>div {
			position: relative;
			width: 33.3%;
			cursor: pointer;
			@media (max-width: 1200px) {
				width: 50%;
			}
	
			@media (max-width: 800px) {
				display: block;
				width: 100%;
			}

			>div {
				-webkit-transition: all $transition-time ease-in-out;
				-moz-transition: all $transition-time ease-in-out;
				-o-transition: all $transition-time ease-in-out;
				transition: all $transition-time ease-in-out;

				@media (max-width: 980px) {
					width: 100%;
				}

				@media (max-width: 800px) {
					margin-bottom: 5px;
				}
			}

			&.lg {
				transition: width 1s;

				>div {
					width: 100%;
				}

			}



		}
	}

	.video-embed {
		position: relative;
		z-index: 9999;

		iframe {
			position: absolute;
		}

		.placeholder {
			position: relative;
			width: 100%;
			z-index: -1;
			opacity: 0;
		}
	}


}



#contact {
	p {
		text-align: justify;
	}
}

#footer {
	padding: 30px 0;
	text-align: center;
	font-size: 0.95rem;
	color: rgba(100, 100, 100, 1)
}


#debug {
	border: 1px solid #fff;
	padding: 10px;
	color: #fff;
	z-index: 999;
	position: relative
}